import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Image} from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import { getCurrencyInfo } from 'utils/GlobalFunctions';

const DetailTable = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, onUpdateAction, onDeleteAction, } = props;
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        return (
          <Image className="detail-widget-image"
            src={record.thumbnail_image}
          />
          // <img className="detail-widget-image" src={record.thumbnail_image} />
        )
      },
    },
    { title: "Description", dataIndex: "description" },
    {
      title: "QTY",
      dataIndex: "qty",
      sorter: {
        compare: (a, b) => a.qty - b.qty
      },
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      sorter: {
        compare: (a, b) => a.unit_name.localeCompare(b.unit_name)
      },
    },
    {
      title: "Spoilage",
      dataIndex: "spoilage",
      sorter: {
        compare: (a, b) => a.spoilage - b.spoilage
      },
    },
    {
      title: "Cost/Unit",
      dataIndex: "price_per_unit",
      sorter: {
        compare: (a, b) => a.price_per_unit - b.price_per_unit
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + text}</span>,
    },

    {
      title: "Category",
      dataIndex: "category_name",
      sorter: {
        compare: (a, b) => a.category_name.localeCompare(b.category_name)
      },
    },
    {
      title: "Location",
      dataIndex: "location_name",
      sorter: {
        compare: (a, b) => a.location_name.localeCompare(b.location_name)
      },
    },
    {
      title: "Created Date",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated Date",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id, name: info.name.name, category_name: info.category.name, unit_name: info.unit.name, location_name: info.location.name };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(DetailTable)
