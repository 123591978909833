import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, Image } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { getCurrencyInfo, isEmpty } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, setDataList } = props;

  if (dataList === undefined) return <div />

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        return (
          <Image className="detail-widget-image"
            src={record.thumbnail_image}
          />
          // <img className="detail-widget-image" src={record.thumbnail_image} />
        )
      },
    },
    { title: "Name", dataIndex: "name" },
    {
      title: "Total Purchase QTY",
      dataIndex: "purchased_qty",
      sorter: {
        compare: (a, b) => a.purchased_qty - b.purchased_qty
      },
      render: text => <span>{parseFloat(text).toFixed(2)}</span>,
    },
    { title: "Unit", dataIndex: "unit_name" },
    {
      title: "Monetary Value",
      dataIndex: "purchased_cost",
      sorter: {
        compare: (a, b) => a.purchased_cost - b.purchased_cost
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "QTY used for recipes",
      dataIndex: "deducted_qty",
      sorter: {
        compare: (a, b) => a.deducted_qty - b.deducted_qty
      },
      render: text => <span>{parseFloat(text).toFixed(2)}</span>,
    },
    { title: "Unit", dataIndex: "unit_name" },
    {
      title: "Monetary Value",
      dataIndex: "deducted_cost",
      sorter: {
        compare: (a, b) => a.deducted_cost - b.deducted_cost
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "Stock Remaining",
      dataIndex: "qty",
      sorter: {
        compare: (a, b) => a.qty - b.qty
      },
      render: text => <span>{parseFloat(text).toFixed(2)}</span>,
    },
    { title: "Unit", dataIndex: "unit_name" },
    {
      title: "Monetary Value",
      dataIndex: "remain_cost",
      sorter: {
        compare: (a, b) => a.remain_cost - b.remain_cost
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + parseFloat(text).toFixed(2)}</span>,
    },

  ];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        pagination={dataList.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(TableWidget)
