import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, Image } from "antd";
import "./CartTable.css";
import { getCurrencyInfo } from 'utils/GlobalFunctions';
const { Option } = Select;

function CartTable(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, RemoveSpoilageAction, ownCommonData } = props;
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "key",
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.thumbnail_image === null) {
                    return (
                        <img className="sales-inv-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <Image className="sales-inv-thumbnail-img"
                            src={record.thumbnail_image}
                        />
                        // <img className="sales-inv-thumbnail-img" src={record.thumbnail_image} />
                    )
                }

            },
        },
        {
            title: "Enter Qty",
            dataIndex: "enter_qty",
            sorter: {
                compare: (a, b) => a.enter_qty - b.enter_qty,
            },
        },
        {
            title: "Spoilage Cost",
            dataIndex: "total_price",
            align: "center",
            sorter: {
                compare: (a, b) => a.total_price - b.total_price,
            },
            render: text => <span>{"$(" + getCurrencyInfo() + ") " + parseFloat(text).toFixed(2)}</span>,
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {

                return <div size="middle" direction={"vertical"}>
                    <a className="sales-btn"
                        onClick={() => {
                            RemoveSpoilageAction(record)
                        }}
                    >
                        Remove
                    </a>
                </div>
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(CartTable)
