import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Image } from "antd";
import RecipeDetailTableWidget from './RecipeDetailTableWidget';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import "./RecipeListTableWidget.css"
import { getCurrencyInfo } from 'utils/GlobalFunctions';

function RecipeListTableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, onUpdateAction, onDeleteAction, ownCommonData } = props;
    if (dataList === undefined || ownCommonData.categoryList === undefined) return <div />

    function createMarkup(html) {
        return { __html: html };
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.thumbnail_image === null) {
                    return (
                        <img className="recipe-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <Image className="recipe-thumbnail-img"
                            src={record.thumbnail_image}
                        />
                    )
                }

            },
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
            render: (text, record) => {
                if (record.sub_recipe === "1") {
                    return <div style={{ color: '#60a8dc' }}>{record.name}</div>
                }
                else {
                    return <div>{record.name}</div>
                }
            }
        },
        {
            title: "QTY",
            dataIndex: "qty",
            sorter: {
                compare: (a, b) => a.qty.localeCompare(b.qty),
            },
        },
        {
            title: "Unit",
            dataIndex: "unit_id",
            align: 'center',
            sorter: {
                compare: (a, b) => a.unit_id - b.unit_id,
            },
            render: (text, record) => {
                const result = ownCommonData.unitList.filter(item => item.id === record.unit_id);
                if (result.length === 0) {
                    return "Empty";

                }
                else {
                    return result[0].name;
                }
            }
        },
        {
            title: "Total Cost",
            dataIndex: "total_price",
            width: 100,
            sorter: {
                compare: (a, b) => a.total_price.localeCompare(b.total_price),
            },
            render: text => <span>{"$(" + getCurrencyInfo() + ") " + text}</span>,
        },
        {
            title: "Selling Price Percentage",
            dataIndex: "sell_price_percentage",
            sorter: {
                compare: (a, b) => a.sell_price_percentage.localeCompare(b.sell_price_percentage),
            },
            render: text => <span> {text}%</span>,
        },
        {
            title: "Selling Price",
            dataIndex: "sell_price",
            width: 120,
            render: (text, record) => {
                if (record.is_checked_fixed_sell_price === "1") {
                    return <span>Fixed  :  ${record.fixed_sell_price}</span>
                } else {
                    return <span> ${parseFloat(100 * record.total_price / record.sell_price_percentage).toFixed(2)}</span>
                }
            }
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: {
                compare: (a, b) => a.description.localeCompare(b.description),
            },
            render: (text, record) => {
                return <div dangerouslySetInnerHTML={createMarkup(record.description)} />;
            },
        },
        {
            title: "Category",
            dataIndex: "category_id",
            sorter: {
                compare: (a, b) => a.category_id - b.category_id,
            },
            render: (text, record) => {
                const result = ownCommonData.categoryList.filter(item => item.id === record.category_id);
                if (result.length === 0) {
                    return "Empty";

                }
                else {
                    return result[0].name;
                }
            },
        },
        {
            title: "Location",
            dataIndex: "location_id",
            sorter: {
                compare: (a, b) => a.location_id - b.location_id,
            },
            render: (text, record) => {
                const result = ownCommonData.locationList.filter(item => item.id === record.location_id);
                if (result.length === 0) {
                    return "Empty";

                }
                else {
                    return result[0].name;
                }
            },
        },
        {
            title: "Author",
            dataIndex: "author",
            sorter: {
                compare: (a, b) => a.author.localeCompare(b.author),
            },
        },
        {
            title: "Sellable Type",
            dataIndex: "salable_type_id",
            sorter: {
                compare: (a, b) => a.salable_type_id - b.salable_type_id,
            },
            render: (text, record) => {
                const result = ownCommonData.salableTypeList.filter(item => item.id === record.salable_type_id);
                if (result.length === 0) {
                    return "---";
                }
                else {
                    return result[0].name;
                }
            },
        },
        {
            title: "Salable",
            dataIndex: "salable",
            sorter: {
                compare: (a, b) => a.salable - b.salable,
            },
            render: (text, record) => {
                if (record.salable === "1") {
                    return <div className="active-btn">Yes</div>
                }
                else {
                    return <div className="inactive-btn">No</div>
                }
            }
        },
        {
            title: "Sub Recipe",
            dataIndex: "sub_recipe",
            sorter: {
                compare: (a, b) => a.sub_recipe - b.sub_recipe,
            },
            render: (text, record) => {
                if (record.sub_recipe === "1") {
                    return <div className="active-btn">Yes</div>
                }
                else {
                    return <div className="inactive-btn">No</div>
                }
            }
        },
        {
            title: "Active",
            dataIndex: "active",
            sorter: {
                compare: (a, b) => a.active - b.active,
            },
            render: (text, record) => {
                if (record.active === "1") {
                    return <div className="active-btn">Yes</div>
                }
                else {
                    return <div className="inactive-btn">No</div>
                }
            }
        },
        {
            title: "Created",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => {
                return showColumnAction(text, record, onUpdateAction, onDeleteAction)
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                expandedRowRender={record => {
                    if (record.guideList.length !== 0) {
                        return <RecipeDetailTableWidget
                            dataList={record.guideList}
                            ownCommonData={ownCommonData}
                            onUpdateAction={onUpdateAction}
                            onDeleteAction={onDeleteAction} />
                    }
                    else {
                        return <div className="ant-table-expanded-row-fixed" style={{ width: "1428px", position: 'sticky', left: 0, overflow: 'hidden' }}>
                            <div className="ant-empty ant-empty-normal">
                                <div className="ant-empty-image">
                                    <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                            <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="ant-empty-description">No Data</div>
                            </div>
                        </div>
                    }

                }}
            />
        </div>
    )
}

export default withRouter(RecipeListTableWidget)