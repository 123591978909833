import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, Image } from "antd";
import DetailTable from './DetailTable';
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import { getCurrencyInfo } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, setDataList, onAppendAction, onUpdateAction, onHistoryAction, onDeleteAction, salableTypeList } = props;
  if (dataList === undefined) return <div />



  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        return (
          <Image className="detail-widget-image"
            src={record.thumbnail_image}
          />
          // <img className="detail-widget-image" src={record.thumbnail_image} />
        )
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: "center",
      sorter: {
        compare: (a, b) => a.qty - b.qty,
      },
      render: text => <span>{parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      sorter: {
        compare: (a, b) => a.unit_name.localeCompare(b.unit_name)
      },
    },
    // {
    //   title: "Spoilage",
    //   dataIndex: "spoilage",
    //   align: "center",
    //   sorter: {
    //     compare: (a, b) => a.spoilage - b.spoilage,
    //   },
    // },
    {
      title: "Average Cost/Unit",
      dataIndex: "average_price_per_unit",
      align: "center",
      sorter: {
        compare: (a, b) => a.average_price_per_unit - b.average_price_per_unit,
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + text}</span>,
    },
    {
      title: "Selling Price Percentage",
      dataIndex: "sell_price_percentage",
      align: "center",
      sorter: {
        compare: (a, b) => a.sell_price_percentage.localeCompare(b.sell_price_percentage),
      },
      render: text => <span>{parseFloat(text).toFixed(2)}%</span>,

    },
    {
      title: "Selling Price",
      align: "center",
      dataIndex: "sell_price",
      width: 120,
      render: (text, record) => {
        if (record.sell_price_percentage == "0") {
          return "---";
        }
        else {
          return <span>{"$(" + getCurrencyInfo() + ") " + parseFloat(100 * record.average_price_per_unit / record.sell_price_percentage).toFixed(2)}</span>

        }

      }
    },
    {
      title: "Sellable Type",
      align: "center",
      dataIndex: "salable_type_id",
      sorter: {
        compare: (a, b) => a.salable_type_id - b.salable_type_id,
      },
      render: (text, record) => {
        const result = salableTypeList.filter(item => item.id === record.salable_type_id);
        if (result.length === 0) {
          return "---";
        }
        else {
          return result[0].name;
        }
      },
    },
    {
      title: "Salable",
      align: "center",
      dataIndex: "salable",
      sorter: {
        compare: (a, b) => a.salable - b.salable,
      },
      render: (text, record) => {
        if (record.salable === "1") {
          return <div className="active-btn">Yes</div>
        }
        else {
          return <div className="inactive-btn">No</div>
        }
      }
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "add_timestamp",
    //   sorter: {
    //     compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
    //   },
    // },
    {
      title: "Updated At",
      align: "center",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => {

        return <div size="middle" direction={"vertical"}>
          <a style={{ color: "var(--orangeColor)", marginLeft: 10 }} className="edit-btn"
            onClick={() => {
              onAppendAction(record)
            }}
          >
            Append
          </a>
          <a style={{ color: "var(--greenColor)", marginLeft: 10 }} className="edit-btn"
            onClick={() => {
              onUpdateAction(record)
            }}
          >
            Edit
          </a>
          <a style={{ color: "var(--yellowColor)", marginLeft: 10 }} className="edit-btn"
            onClick={() => {
              onHistoryAction(record)
            }}
          >
            History
          </a>
          <a style={{ color: "var(--redColor)", marginLeft: 10 }} className="del-btn" onClick={async () => {
            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
            if (res == false) return;
            onDeleteAction(record)
          }}>
            Delete
          </a>
        </div>
      },
    },
  ];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id, unit_name: info.unit.name };
  });

  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={dataSource}
      // expandedRowRender={record => {
      //   if (record.total_count !== 0) {
      //     return <DetailTable
      //       dataList={record.subItemList} />
      //   }
      //   else {
      //     return <div className="ant-table-expanded-row-fixed" style={{ width: "1428px", position: 'sticky', left: 0, overflow: 'hidden' }}>
      //       <div className="ant-empty ant-empty-normal">
      //         <div className="ant-empty-image">
      //           <svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
      //             <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      //               <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
      //               <g className="ant-empty-img-simple-g" fillRule="nonzero">
      //                 <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path>
      //               </g>
      //             </g>
      //           </svg>
      //         </div>
      //         <div className="ant-empty-description">No Data</div>
      //       </div>
      //     </div>
      //   }

      // }}
      />
    </div>
  )
}

export default withRouter(TableWidget)
