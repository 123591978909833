import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber, Image } from "antd";
import "./TableWidget.css";
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import { getCurrencyInfo } from 'utils/GlobalFunctions';
const { Option } = Select;

function TableWidget(props) {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, setDataList, AddEntAction, ownCommonData, handleChangeQtyforEachItem } = props;
  if (dataList === undefined || ownCommonData.unitList === undefined) return <div />

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        if (record.thumbnail_image === null) {
          return (
            <img className="sales-inv-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
          )
        }
        else {
          return (
            <Image className="sales-inv-thumbnail-img"
              src={record.thumbnail_image}
            />
            // <img className="sales-inv-thumbnail-img" src={record.thumbnail_image} />
          )
        }

      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      sorter: {
        compare: (a, b) => a.qty - b.qty,
      },
      render: text => <span>{parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit_id",
      align: 'center',
      sorter: {
        compare: (a, b) => a.unit_id - b.unit_id,
      },
      render: (text, record) => {
        const result = ownCommonData.unitList.filter(item => item.id === record.unit_id);
        if (result.length === 0) {
          return "Empty";

        }
        else {
          return result[0].name;
        }
      }
    },
    {
      title: "Cost/Unit",
      dataIndex: "average_price_per_unit",
      align: "center",
      sorter: {
        compare: (a, b) => a.average_price_per_unit - b.average_price_per_unit,
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + text}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated Date",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
    {
      title: "Enter QTY",
      key: "enter_qty",
      width: 150,
      render: (text, record) => {

        return <InputNumber
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            background: `var(--backgroundColor)`,
            padding: "4px 12px",
            width: "100%"
          }}
          value={
            record === undefined || record["enter_qty"] == undefined ? "0" : record["enter_qty"]
          }
          defaultValue={0}
          min={"0"}
          step={"1"}

          onChange={(value) => {
            record["enter_qty"] = value
            handleChangeQtyforEachItem(record)
            // let updateData = [...dataList]
            // var storeIndex = updateData.findIndex(x => x.id == record.id);
            // updateData.splice(storeIndex, 1, record);
            // var tmpData = [...updateData];
            // setDataList(tmpData);
          }} />
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => {

        return <div size="middle" direction={"vertical"}>
          <a className="sales-btn"
            onClick={() => {
              if (record.enter_qty === "" || record.enter_qty === undefined) {
                record["enter_qty"] = 1
                let updateData = [...dataList]
                var storeIndex = updateData.findIndex(x => x.id == record.id);
                updateData.splice(storeIndex, 1, record);
                var tmpData = [...updateData];
                setDataList(tmpData);
              }
              AddEntAction(record)
            }}
          >
            Add to ENT
          </a>
        </div>
      },
    },
  ];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(TableWidget)
