import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Image} from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';

const DetailTable = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, onUpdateAction, onDeleteAction, } = props;
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        return (
          <Image className="detail-widget-image" 
            src={record.thumbnail_image}
          />
          // <img className="detail-widget-image" src={record.thumbnail_image}/>
        )
      },
    },
    { title: "Name", dataIndex: "name" },
    {
      title: "QTY",
      dataIndex: "enter_qty",
      sorter: {
        compare: (a, b) => a.enter_qty - b.enter_qty
      },
    },
    {
      title: "Cost/Unit",
      dataIndex: "price",
      sorter: {
        compare: (a, b) => a.price - b.price
      },
      render: text => <div>$ {text}</div>,
    },
    {
      title: "Total Cost",
      dataIndex: "total_price",
      sorter: {
        compare: (a, b) => a.total_price - b.total_price
      },
      render: text => <div>$ {text}</div>,
    },
    {
      title: "Created Date",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated Date",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(DetailTable)
