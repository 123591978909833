import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin, DatePicker } from "antd";

import "./AddExpenseDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

function AddExpenseDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({ month_date: moment().startOf("month").format(dateFormat), is_year_method : '0', year_date : moment().startOf("year").format(dateFormat), cost_per_year : 0, cost_per_month : 0 });
    }
  }, [record]);

  function onChangeMonthDatePicker(date, dateString) {
    if (!dateString) {
      message.info("Please select start date")
      return
    }
    var tmpData = data
    tmpData["month_date"] = date.format(dateFormat)

    setData({ ...tmpData })
  }

  function onChangeYearDatePicker(date, dateString) {
    if (!dateString) {
      message.info("Please select start date")
      return
    }
    var tmpData = data
    tmpData["year_date"] = date.format(dateFormat)

    setData({ ...tmpData })
  }

  const validateFields = async () => {

    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    if (data["is_year_method"] == '1') {
      errorList = isEmpty(data, "cost_per_year", errorList);
    } else {
      errorList = isEmpty(data, "cost_per_month", errorList);
    }
    
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const onSelectMethod = e => {
    var tmpData = data
    tmpData["is_year_method"] = e.target.value
    setData({ ...tmpData })
  }
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-expense-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Expense Avatar*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Enter Name*</div>
              <ProductDescriptionInput
                placeholder="name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} >
              <Radio.Group onChange={onSelectMethod} value={data["is_year_method"]}>
                <Radio value={'0'}>Month</Radio>
                <Radio value={'1'}>Year</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Month*</div>
              <DatePicker
                disabled={data["is_year_method"] == '1'}
                value={dayjs(data["month_date"], dateFormat)}
                onChange={onChangeMonthDatePicker}
                picker="month"
                size='middle'
                style={{
                  border: errorField !== undefined && errorField.includes("month_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width: "100%"
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Monthly Cost*</div>
              <ProductInputNumber
                disabled={data["is_year_method"] == '1'}
                field={"cost_per_month"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Select Year*</div>
              <DatePicker
                value={dayjs(data["year_date"], dateFormat)}
                onChange={onChangeYearDatePicker}
                disabled={data["is_year_method"] == '0'}
                picker="year"
                size='middle'
                style={{
                  border: errorField !== undefined && errorField.includes("year_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width: "100%"
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Year Cost*</div>
              <ProductInputNumber
                disabled={data["is_year_method"] == '0'}
                field={"cost_per_year"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>

        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddExpenseDialog)
